@import './reset.css';
/* @import './transition.css'; */

/* html{background: url(http://www.uniceramics.com.cn/live/images/pc-bg.jpg) #fff fixed repeat-y;} */
#root {margin: 0 auto; width:100%;}

html{background: #f1f1f1;}
body{background: #fff; margin:0 auto; overflow-y: scroll;}
#nprogress .bar {background: #d70e19!important;}

.pages-base{min-height: calc( 100vh - 90px - 81px - 274px );width:1171px; margin:90px auto 0 auto; padding-top:10px;}

.mobile-hide{display:block!important;}
.pc-hide{display:none!important;}


.ell{display: inline-block; overflow:hidden;  text-overflow:ellipsis;  -webkit-text-overflow:ellipsis; white-space:nowrap;}
.ell2{display: -webkit-box; overflow: hidden; text-overflow: ellipsis; -webkit-line-clamp: 2; -webkit-box-orient: vertical; }
.ell3{display: -webkit-box; overflow: hidden; text-overflow: ellipsis; -webkit-line-clamp: 3; -webkit-box-orient: vertical; }
.flexbox{display:flex;}

.button-submit{display: block; height:48px; line-height:48px; text-align: center; font-size:20px; font-weight: 300; margin-top:40px; background: #04367b; color:#fff; border-radius: 5px;}
.button-submit:active{opacity: .8; color:#fff;}

.dashed-elem{position: absolute; z-index:999999; display: flex; align-items: center; justify-content: center; font-size:16px; border:1px dashed #333; color:#666; user-select: none;}

.block{box-sizing: border-box;}

.ant-empty{margin:10px 0 5px 0;}
.ant-empty-image{height: 50px;}
.ant-descriptions-title{display:flex; align-items: center; color:#222;}
.ant-descriptions-title:before{content: ''; display: inline-block; height:16px; vertical-align: middle; border-left:3px solid red; margin-right:10px;}

.page-animate{opacity:0; transition-duration: 300ms; left:40px; position:relative;}
.page-animate.show{opacity:1; transition-duration: 300ms; left:0px; position:relative;}
.ant-btn-primary{background:#04367b; border-color: #04367b;}
.ant-btn-primary.ant-btn{font-size:16px;}
.ant-btn:hover, .ant-btn:focus {color: #273ba5;background: #fff;border-color: #273ba5;}
.ant-btn-primary:hover, .ant-btn-primary:focus {color: #fff;background: #273ba5;border-color: #273ba5;}
.ant-checkbox-checked .ant-checkbox-inner {background-color: #04367b; border-color: #04367b;}
.ant-checkbox-checked::after{border: 1px solid #6d1c9c;}
.ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner{border-color: #273ba5;}

.ant-radio-checked::after{border: 1px solid #6d1c9c;}
.ant-radio-wrapper:hover .ant-radio-inner, .ant-radio:hover .ant-radio-inner, .ant-radio-input:focus + .ant-radio-inner{border-color: #273ba5;}
.ant-radio-checked .ant-radio-inner{border-color: #273ba5;}
.ant-radio-inner::after{background-color: #04367b;}

:where(.css-dev-only-do-not-override-i0102m).ant-spin .ant-spin-dot-item{background-color:#d10b2f;}

.titlebox{font-size:18px; line-height: 16px; color:#111; font-weight: bold; margin:20px 0 20px 0; display:flex;}
.titlebox::after{content:''; display:block; flex:1 1 auto; height:2px; background: #f4f4f4; margin-left:15px; margin-top:8px;}
.rightbox .titlebox{margin:40px 0 20px 0;}



.listbox .list{display: flex; margin-bottom:20px; color:#333; border-bottom:1px solid #ddd; padding:10px 0;}
.listbox .list:nth-last-child(1){border:none;}
.listbox .list .imgbox{margin-left:10px; width:160px; height:100px; background: #8c97ab; display:flex; border:1px solid #ddd;}
.listbox .list .imgbox img{ width:160px; height:100px;  margin:auto; object-fit:cover;}
.listbox .list .rightbox{flex: 1 1 auto;  flex-direction: column; }
.listbox .list .label{ min-width: 60px; height:28px; padding:0 10px; line-height:28px; background: #e05050; font-size:13px; color:#fff; text-align: center; display: inline-block;}

.listbox .list.listtype1 .label{background: #465e8a; }
.listbox .list.listtype2 .label{background: #9a6261; }
.listbox .list.listtype3 .label{background: #8c97ab; }
.listbox .list.listtype4 .label{background: #e05050; }

.listbox .list h4{font-size:22px; line-height:28px; margin:5px 0 0px 0;}
.listbox .list h5.desp{font-size:14px; line-height:24px; margin:0px 0 5px 0; font-weight: normal; color:#999;}

.listbox .list .time{font-size:12px; color:#999;}

.listbox.type-square{width:373px; flex-wrap: wrap;}
.listbox.type-square .list{flex-direction: column; width:373px; border-bottom:none; padding:0px 0;}
.listbox.type-square .imgbox{margin-left:0px; width:373px; height:215px;}
.listbox.type-square .list .imgbox img{ width:373px; height:215px; object-fit:cover;}
.listbox.type-square .list .rightbox{margin-top:10px;}
.listbox.type-square .list h4{font-size:16px; line-height:25px;}


.listbox.type-mini .list{width:373px; border-bottom:none; padding:0px 0;}
.listbox.type-mini .imgbox{width:133px; height:78px;}
.listbox.type-mini .list .imgbox img{ max-width:133px; max-height:78px;}
.listbox.type-mini .list h4{font-size:14px; line-height:21px;}

.emptybox{width:60px; margin:40px auto;}
.emptybox .img{width:60px; height:60px; background: url(../assets/icon-empty.png); background-size: 100% 100%; opacity:.5;}
.emptybox .tips-text{color:#666; font-size:14px; text-align: center;}

/* 覆盖antd主题色 */
/* :where(.css-dev-only-do-not-override-1adbn6x).ant-pagination .ant-pagination-item-active{border-color: #d10b2f;}
:where(.css-dev-only-do-not-override-1adbn6x).ant-pagination .ant-pagination-item-active:hover{border-color: #d10b2f;}
:where(.css-dev-only-do-not-override-1adbn6x).ant-pagination .ant-pagination-item-active a{color:#d10b2f;}
:where(.css-dev-only-do-not-override-1adbn6x).ant-pagination .ant-pagination-item-active a:hover{color:#d10b2f;} */

input[type=checkbox] {
    cursor: pointer;
    position: relative;
    width: 15px;
    height: 15px;
    font-size: 14px;
}

input[type=checkbox]::after {
    position: absolute;
    top: 0;
    color: #000;
    width: 15px;
    height: 15px;
    display: inline-block;
    visibility: visible;
    padding-left: 0px;
    text-align: center;
    content: ' ';
    border-radius: 3px
}

input[type=checkbox]:checked::after {
    content: "✓";
    color: #fff;
    font-size: 12px;
    font-weight: bold;
    background-color: #c9182a;
}


.breadcrumb{padding:10px 0; display:flex; font-size:14px;}
.breadcrumb a{color:#555;  font-size:14px;}
.breadcrumb a:hover{text-decoration: underline;}
.breadcrumb p{color: #888; font-size:14px;}
.breadcrumb span::after{content:">"; margin:0 10px; color:#666;}

/*广告相关样式*/
.ad-big img{width: 1171px;height: 137px; background: #a7a7a7;}
.ad-min img{width: 770px; height: 106px;}
.ad-square{width:300px; height:300px; margin: 0px auto 20px auto;}
.ad-square img{width:300px; height:300px;background: #a7a7a7; }

@media screen and (max-width: 500px) {
    body,html{overflow-x: hidden;}
    .mobile-hide{display:none!important;}
    .pc-hide{display:block!important;}
    .breadcrumb{display: none;}

    .pages-base{min-height: calc( 100vh - 85px - 81px - 274px ); width:414px; margin:auto; margin-top:85px; padding-top:0; overflow-x: hidden;}

    .titlebox{font-size:15px; line-height: 15px; color:#030102; font-weight: bold; margin:15px auto; display:flex; width:394px; }
    .titlebox::after{content:''; display:block; flex:1 1 auto; height:2px; background: #f4f4f4; margin-left:15px; margin-top:8px;}

    .listbox .list{display: flex; margin-bottom:25px;}
    .listbox .list .imgbox{margin-left:10px; width:143px; flex:0 0 143px; height:87px; background: #8c97ab; display:flex;}
    .listbox .list .imgbox img{ width:143px; height:87px;  margin:auto; object-fit:cover;}
    .listbox .list .rightbox{flex: 1 1 auto; flex-direction: column; }
    .listbox .list .label{min-width:65px; height:20px; line-height:20px; background: #e05050; font-size:12px; color:#fff; text-align: center; }
    .listbox .list h4{font-size:14px; line-height:24px; max-width: 500px; margin:5px 0 6px 0; -webkit-line-clamp: 2;}
    .listbox .list h5.desp{display: none;}
    .listbox .list .time{font-size:12px; color:#999;}

    .listbox.type-square{width:192px;}
    .listbox.type-square .list{flex-direction: column; width:192px;}
    .listbox.type-square .imgbox{margin-left:0px; width:192px; height:143px;}
    .listbox.type-square .list .imgbox img{ width:192px; height:143px; object-fit:cover;}
    .listbox.type-square .list .rightbox{margin-top:10px;}
    .listbox.type-square .list h4{font-size:14px; line-height:25px;}


    .listbox.type-mini .list{width:394px;}
    .listbox.type-mini .imgbox{width:143px; flex:0 0 143px; height:77px;}
    .listbox.type-mini .list .imgbox img{ max-width:143px; max-height:77px;}
    .listbox.type-mini .list h4{font-size:14px; line-height:21px;}


    /*广告相关样式*/
    .ad-big img{width: 414px;height: 49px; background: #a7a7a7; margin: 0px;}
    .ad-min img{width: 414px;height: 49px; background: #a7a7a7; margin: 0px;}
    .ad-square{width: 394px;height: 394px; margin: 10px auto;}
    .ad-square img{width: 394px;height: 394px;}
}
