.loginBox{position:fixed;left:0;right:0;top:78px;bottom:0;z-index:990; visibility: visible; opacity:1; transition-duration: 300ms;}
.loginBox.hide{visibility: hidden; opacity:0;}
.loginBox .maskbg{position:fixed;left:0;right:0;top:78px;bottom:0;z-index:900; background:rgba(0,0,0,.5);}
.loginBox .wrap{background:#be0f2c; position:fixed; z-index:901; left:0;right:0;top:78px; height:149px;}
.loginBox .center{width:919px; margin:auto; color:#fff;}
.loginBox .center h2{font-size:21px;}
.loginBox .center>h2{margin:29px 0 15px 0;}

.loginBox .center .flexbox{display: flex; justify-content: space-between;}
.loginBox .center .flexbox input{height:44px; width:220px; line-height:44px; border:none; outline: none; text-indent: 10px; margin-right:6px; font-size:14px;}

.loginBox .center .flexbox input:nth-of-type(1){background: rgba(0,0,0,.5); color:#fff;}
.loginBox .center .flexbox input:nth-of-type(1)::placeholder{color:#fff;}

.loginBox .center .flexbox button{width:74px; height:44px; background: #272727; color:#FFF; font-size:14px;}

.loginBox .center .a{text-decoration: underline; cursor: pointer; color:#fff;} 

.loginBox .center .findpass{display: inline-block; margin-left:25px; vertical-align: bottom; padding-bottom:5px;}

.loginBox .close{position:fixed;right:0;top:78px; height:52px; z-index:902; cursor:pointer;}
.loginBox .close img{width:26px; height:26px; padding:2px; border-radius: 26px; border:0px solid #fff; margin:10px;  opacity:1; transition-duration: 300ms;}
.loginBox.hide .close img{transform: rotate(45deg); opacity:0;}



@media screen and (max-width: 500px) {
    .loginBox .wrap{background:#be0f2c; position:fixed; z-index:901; left:15px; right:15px; top:50%; margin-top:-180px; height:360px;}
    .loginBox .center{width:384px; text-align:center;}
    .loginBox .center h2{font-size:26px;}
    .loginBox .center>h2{margin:29px 0 25px 0;}
    .loginBox .center .flexbox{display:block;}
    .loginBox .center .flexbox input{width:340px; display: block; margin:10px auto;}
    .loginBox .center .flexbox button{width:170px; margin-top:20px; margin-bottom:24px;}

    .loginBox .close{position:fixed;right:10px; top:50%; margin-top:-180px; height:52px; z-index:902; cursor:pointer;}

}