header{height:90px; box-shadow: 1px 2px 5px #999; overflow: hidden; position: fixed; left:0; right:0; top:0; z-index:999; background:#fff; user-select: none;}
header>.wrap{width: 1171px; margin:auto;}
header>.wrap .flexbox{display:flex; margin-top:8px;}
header>.wrap .flexbox img.ctwlogo{flex:0 0 90px; width:165px; margin-top:25px;}
header>.wrap .flexbox .navbox{margin-left:30px; flex:1 1 auto; margin-top:48px;}
header>.wrap .flexbox .navbox a{height:16px; color:hsl(0, 0%, 7%); padding:5px 5px; margin:0 7px; font-size:18px; font-weight: 400;}
header>.wrap .flexbox .navbox a.active{color:#c71b29; font-weight: bold; }

header>.wrap .loginbtnbox{display:flex; display: none; }
header>.wrap .loginbtnbox a{margin:0 3px;}
header>.wrap .loginbtnbox a:nth-of-type(2){margin-top:2px;}
header>.wrap .loginbtnbox .svgbox{margin-top:44px; cursor: pointer;}
header>.wrap .loginbtnbox .svgbox img{width:20px; height:20px;}
header>.wrap .loginbtnbox .svgbox:active img{filter: drop-shadow(20px 0 #d10b2f) !important;}
header>.wrap .loginbtnbox .userlogo{width:30px; height:30px; margin-top:38px; cursor: pointer;}


/* header>.wrap .search-box{margin-top:22px; border:1px solid #ccc; display:flex; align-items: center; width:210px; height:34px; border-radius: 5px;}
header>.wrap .search-box input{width:140px; height:22px; padding:3px 0; flex:1 1 auto; margin:0 5px; border:none; outline: none;}
header>.wrap .search-box .clearSearch{font-size:12px; color:#666; display:block; height:22px; line-height:22px; background:#eee; padding:0 5px; border-radius: 5px; cursor:pointer;}
header>.wrap .search-box .clearSearch{background:url(../assets/icon_close2.png) no-repeat; background-size:100% 100%; display: inline-block; width: 18px; height:18px; cursor:pointer;}
header>.wrap .search-box .svgbox{margin-right:5px; margin-left:5px; cursor:pointer;} */


header>.wrap .follow-us{margin:38px 20px 0 30px; padding:0 20px; position: relative;}
/* header>.wrap .follow-us::after{content: ''; display:block; position: absolute; top:8px; height:18px; width:1px; right:0; border-right: 1px solid #eee;} */
header>.wrap .follow-us a img{width:34px; height:34px;}

@media screen and (max-width: 500px) {
    header{height:85px;}
    header>.wrap{width: 414px; margin:auto;}
    header>.wrap .flexbox{justify-content: space-between;margin-top:2px;}
    header>.wrap .flexbox img.ctwlogo{flex:0 0 121px; width:121px; height:34px;margin:5px;}
    header>.wrap .flexbox .rightbox{display:flex;}
    header>.wrap .follow-us{margin:9px 10px 0 0px;}

    header>.wrap .flexbox .navbox a{height:16px; color:hsl(0, 0%, 7%); padding:5px 2px; margin:0 6px; font-size:15px; font-weight: 400;}

    header>.wrap .loginbtnbox{margin-top:8px; margin-right:10px;}
    header>.wrap .loginbtnbox .svgbox{margin-top:4px;}
    header>.wrap .loginbtnbox .svgbox img{width:22px; height:22px;}
    header>.wrap .loginbtnbox .svgbox:active img{filter: drop-shadow(22px 0 #d10b2f) !important;}
    header>.wrap .loginbtnbox .userlogo{width:30px; height:30px; margin-top:0px;}

    /* header>.wrap .follow-us{display:none} */
    header>.wrap .follow-us{padding:0 10px; position: relative;}
    header>.wrap .follow-us::after{content: ''; display:block; position: absolute; top:10px; height:18px; width:1px; right:0; border-right: 1px solid #eee;}
    header>.wrap .follow-us a img{width:28px; height:28px;}
    header>.wrap .flexbox .navbox{margin-top: 0; margin-left:0; border-top:1px solid #ddd; padding:7px 0;}
    
    /* header>.wrap .search-box{margin-top:6px; border:1px solid #ccc; display:flex; align-items: center; width:150px; height:30px; border-radius: 5px;}
    header>.wrap .search-box input{width:80px; height:22px; padding:3px 0; flex:1 1 auto; margin:0 5px; border:none; outline: none;} */
}
