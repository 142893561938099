header .wrap .search-box{margin-top:36px; border:1px solid #ccc; display:flex; align-items: center; width:210px; height:34px; border-radius: 5px;}
header .wrap .search-box input{width:140px; height:22px; padding:3px 0; flex:1 1 auto; margin:0 5px; border:none; outline: none; text-indent:5px;}
/* header .wrap .search-box .clearSearch{font-size:12px; color:#666; display:block; height:22px; line-height:22px; background:#eee; padding:0 5px; border-radius: 5px; cursor:pointer;} */
header .wrap .search-box .clearSearch{visibility: hidden; opacity:0; transform: rotate(90deg); transition-duration: 300ms; background:url(../assets/icon_close2.png) no-repeat; background-size:100% 100%; display: inline-block; width: 18px; height:18px; cursor:pointer;}
header .wrap .search-box .clearSearch.show{visibility: visible; opacity:1; transform: rotate(0deg);}
header .wrap .search-box .svgbox{margin:0; padding:0; font-size:0; margin-right:5px; margin-left:5px; cursor:pointer;}
header .wrap .search-box button{ margin:0; padding:0; width:20px; height:20px; border:0;outline:0; margin-right:5px; margin-left:5px; background:url(../assets/icon-search.png) no-repeat; background-size:100% 100%; }

@media screen and (max-width: 500px) {
    header .wrap .search-box{margin-top:6px; border:1px solid #ccc; display:flex; align-items: center; width:150px; height:30px; border-radius: 5px;}
    header .wrap .search-box input{width:80px; height:22px; padding:3px 0; flex:1 1 auto; margin:0 5px; border:none; outline: none;}
}